<template>
  <div class="form">
    <el-form
      ref="formEl"
      :model="formData"
      :rules="formRules"
      v-loading="loading"
      label-width="140px"
      label-position="right">
      <template v-for="(item, index) in formData">
        <el-form-item
          v-if="!!!item.hidden"
          :key="index"
          :label="item.label"
          :prop="index"
          :class="{ 'is-error': item.errorMsg }"
          class="form-item">
          <div class="form-left">
            <!-- 按钮 -->
            <el-button
              :disabled="item.disabled"
              type="primary"
              @click="change(item, index, $event)"
              v-if="item.type === 'button'">
              {{ item.label || '' }}
            </el-button>
            <!-- 输入框 -->
            <el-input
              v-if="item.type === 'text'"
              v-model.trim="item.value"
              :disabled="item.disabled"
              :style="item.append ? 'width:200px' : ''"
              :placeholder="item.placeholder || t('form.inputPlaceholder')"
              @blur="change(item, index, $event)" />
            <!-- 密码框 -->
            <el-input
              v-if="item.type === 'password'"
              v-model.trim="item.value"
              :disabled="item.disabled"
              :style="item.append ? 'width:200px' : ''"
              :placeholder="item.placeholder || t('form.inputPlaceholder')"
              type="password"
              autocomplete="new-password"
              show-password
              @input="change(item, index, $event)" />
            <!-- 开关 -->
            <el-switch
              v-if="item.type === 'switch'"
              @change="change(item, index, $event)"
              v-model="item.value"
              :disabled="item.disabled" />
            <!-- 下拉框 -->
            <el-select
              v-else-if="item.type === 'select'"
              v-model="item.value"
              filterable
              :disabled="item.disabled"
              :loading="item.loading"
              :placeholder="item.placeholder || t('form.selectPlaceholder')"
              @change="change(item, index, $event)"
              @focus="focus(item, index, $event)">
              <el-option
                v-for="(el, key) in item.options"
                :label="el.label || el.name"
                :key="key"
                :value="el.value || el.id" />
            </el-select>
            <!-- 文本框 -->
            <el-input
              v-if="item.type === 'textarea'"
              v-model="item.value"
              :disabled="item.disabled"
              :placeholder="item.placeholder || t('form.inputPlaceholder')"
              :rows="6"
              autocomplete="off"
              type="textarea"
              @input="change(item, index, $event)" />
            <!-- 计数器 -->
            <el-input-number
              v-if="item.type === 'number'"
              v-model.trim="item.value"
              :precision="0"
              :disabled="item.disabled"
              :min="1"
              :max="item.max || Infinity"
              @change="change(item, index, $event)" />
            <span v-if="item.unit" style="margin-left: 10px;">({{ item.unit }})</span>
            <!-- 单选框 -->
            <el-radio-group
              v-if="item.type === 'radio'"
              v-model="item.value"
              :disabled="item.disabled"
              @change="change(item, index, $event)">
              <el-radio
                v-for="(el, key) in item.options"
                :label="el.value || el.id"
                :key="key"
                :value="el.value || el.id">
                {{ el.label || el.name }}
              </el-radio>
            </el-radio-group>
            <!-- 是否显示刷新icon -->
            <el-icon v-if="item.showRefresh" class="refresh" @click="onRefresh(item, index, $event)">
              <Refresh />
            </el-icon>
          </div>
          <slot v-if="item.type && item.type === 'slot'" :name="index"></slot>
          <div v-if="item.tips" :class="item.tipsClass" v-html="item.tips" class="form-tips" @click="tipClick($event)"></div>
          <div v-if="item.errorMsg" class="el-form-item__error">{{ item.errorMsg }}</div>
          <div v-if="item.top_tip && index !== 'timeType'" v-html="item.top_tip" class="top_tip_form"></div>
        </el-form-item>
        <div v-if="item.top_tip && index === 'timeType'" v-html="item.top_tip" class="top_tip_form top_tip_form_time"></div>
      </template>
    </el-form>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const t = inject('t')
const emit = defineEmits(['onSubmit', 'cancel', 'change', 'refresh', 'tipClick', 'focus'])
const formEl = ref();
const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  },
  formRules: {
    type: Object,
    default: () => {}
  },
  loading: {
    type: Boolean,
    default: true
  }
})

// 清空表单解除校验
const clearForm = () => {
  formEl.value.resetFields()
}
// 刷新
const onRefresh = (item, prop, event) => {
  emit('refresh', {
    item, prop, event
  })
}
// change事件
const change = (item, prop, event, hasFocus) => {
  emit('change', {
    item, prop, event
  })
}
// focus事件
const focus = (item, prop, event, hasFocus) => {
  emit('focus', {
    item, prop, event
  })
}
// 提示文字点击事件
const tipClick = (event) => {
  emit('tipClick', event)
}
// 提交表单
const submitForm = () => {
  return new Promise((resolve, reject) => {
    formEl.value.validate((valid) => {
      if (valid) {
        resolve(1)
      } else {
        console.log('error submit!')
      }
    })
  })
}

defineExpose({
  clearForm: clearForm,
  submitForm: submitForm
})
</script>

<style lang="scss">
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order_info {
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #ededed;

  .order {
    width: 50%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;

    .order_label {
      width: 110px;
      margin-right: 10px;
      background: #ededed;
      padding: 0 10px;
    }
  }

  .order:first-child {
    border-top: 1px solid #ededed;
  }

  .order:nth-child(2) {
    border-top: 1px solid #ededed;
  }
}
.form-left {
  width: 100%;
  display: flex;
  align-items: center;
  .refresh {
    font-size: 20px;
    margin-left: 8px;
    cursor: pointer;
    color: $color3;
    &:hover {
      color: var(--el-color-primary);
    }
  }
}
.form-tips {
  width: 100%;
  font-size: 12px;
  line-height: 22px;
}
.primary-tips {
  position: absolute;
  top: 28px;
  font-size: 12px;
  color: var(--el-color-primary);
  text-align: right;
}
.top_tip_form {
  margin-bottom: -8px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  margin-top: -4px;
  color: var(--el-color-danger);
}
.top_tip_form_time {
  margin-left: 170px;
  margin-top: -20px;
  margin-bottom: 16px;
}
</style>