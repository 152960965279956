<template>
  <el-dialog
    v-model="winShow"
    width="960px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('order.create_order')"
    @closed="closeWin">
    <div class="form">
      <custem-form
        ref="form"
        :loading="formLoading"
        :formData="formData"
        :formRules="formRules"
        @change="change">
        <template #parent_order_id>
          <div class="orderinfo">
            <el-button @click="selectOriginalOrder" type="primary">{{ t('order.select_origional_order') }}</el-button>
            <div v-if="!orderInfoLoading" class="margin-t4">
              <custem-detail
                :loading="orderInfoLoading"
                :descriptions="orderInfo" />
            </div>
          </div>
        </template>
      </custem-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button :loading="submitLoading" @click="onSubmit" type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
    <!-- 订单列表 -->
    <custem-orderList ref="orderlist" @setOrderInfo="setOrderInfo" />
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, nextTick, getCurrentInstance, watch } from 'vue'
import { rules } from '@/utils/rules'
import { enterpriseManagement, userManagement, productManagement, AuthManagement } from '@/utils/api'
import store from '@/store'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()
const emit = defineEmits(['getList', 'sureOrder'])
const winShow = ref(false)
const router = useRouter()
const t = inject('t')
const form = ref()
const formLoading = ref(false)
const submitLoading = ref(false)
const orderlist = ref()
const orderInfoLoading = ref(true)
const orderinfo = ref(null)
const formRules = ref({
  category_id: rules,
  enterprise_id: rules,
  user_id: rules,
  parent_order_id: rules,
  product_id: rules,
  quantity: rules,
  service_type: rules,
  delivery_method: rules,
  expected_payment_time: rules,
  is_stamp: rules,
  stamp_method: rules,
  stamp_time: rules,
  entity_stamp_type: rules,
  electron_stamp_type: rules
})
const formData = reactive({
  enterprise_name: { // 企业名称
    type: 'slot',
    hidden: true
  },
  category_id: { // 订单类型
    type: 'select',
    label: t('order.order_type'),
    value: 1001,
    options: [
      {
        name: t('order.type_1001'),
        value: 1001
      },
      {
        name: t('order.type_1002'),
        value: 1002
      },
      {
        name: t('order.type_1003'),
        value: 1003
      }
    ]
  },
  enterprise_id: { // 选择企业
    label: t('order.select_enterprise_create'),
    type: 'select',
    options: []
  },
  user_id: { // 企业用户
    label: t('order.select_enterprise_user_create'),
    type: 'select',
    options: []
  },
  parent_order_id: { // 选择原订单 - 补发时显示
    label: t('order.select_origional_order'),
    type: 'slot',
    hidden: true
  },
  product_id: { // 选择产品 - 补发订单无需选择产品
    label: t('order.select_product_create'),
    type: 'select',
    options: []
  },
  delivery_method: { // 发货方式
    label: t('order.delivery_method'),
    type: 'select',
    options: [
      {
        name: t('order.pay_and_post'),
        id: 'send_upon_payment'
      },
      {
        name: t('order.post_and_pay'),
        id: 'payment_after_delivery'
      }
    ]
  },
  expected_payment_time: { // 预计付款时间
    label: t('order.expected_payment_time'),
    type: 'text',
    hidden: true,
  },
  is_stamp: { // 是否盖章
    label: t('order.is_stamp'),
    type: 'select',
    options: [
      {
        name: t('table.yes'),
        id: '1'
      },
      {
        name: t('table.no'),
        id: '0'
      }
    ]
  },
  stamp_method: { // 盖章方式
    label: t('order.stamp_method'),
    type: 'select',
    hidden: true,
    options: [
      {
        name: t('order.real_stamp'),
        id: 'entity'
      },
      {
        name: t('order.ele_stamp'),
        id: 'digital'
      }
    ]
  },
  entity_stamp_type: { // 实体公章 - 用章类型
    label: t('order.entity_stamp_type'),
    type: 'select',
    hidden: true,
    options: [
      {
        name: t('order.official_seal'),
        id: 'official_seal'
      },
      {
        name: t('order.contract_seal'),
        id: 'contract_seal'
      },
      {
        name: t('order.legal_representative_seal'),
        id: 'legal_representative_seal'
      },
      {
        name: t('order.financial_seal'),
        id: 'financial_seal'
      }
    ]
  },
  electron_stamp_type: { // 电子章 - 用章类型
    label: t('order.electron_stamp_type'),
    type: 'select',
    hidden: true,
    options: [
      {
        name: t('order.official_seal'),
        id: 'official_seal'
      },
      {
        name: t('order.contract_seal'),
        id: 'contract_seal'
      }
    ]
  },
  stamp_time: { // 用章时间
    label: t('order.stamp_time'),
    type: 'text',
    hidden: true
  },
  service_type: { // 选择服务类型
    label: t('order.select_service_type'),
    type: 'select',
    value: 'standard',
    hidden: true,
    options: [
      {
        name: t('order.standard'),
        id: 'standard'
      },
      {
        name: t('order.failback'),
        id: 'failback'
      }
    ]
  },
  quantity: { // 订购数量
    label: t('order.buy_num'),
    type: 'number',
    value: 1
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'textarea'
  }
})
// 订单详情
const orderInfo = ref([
  { // 订单编号
    label: t('order.order_code'),
    prop: 'code'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name'
  },
  { // 订单创建时间
    label: t('order.order_create'),
    prop: 'created_at'
  },
  { // 分配到期时间
    label: t('order.ass_end_time'),
    prop: 'expired_at'
  },
  { // 订单总数
    label: t('order.order_total'),
    prop: 'order_total'
  },
  { // 当前可用
    label: t('order.can_use'),
    prop: 'can_use'
  }
])
// 新购订单相关商务参数
const fields = ref(['delivery_method', 'expected_payment_time', 'is_stamp', 'stamp_method', 'entity_stamp_type', 'electron_stamp_type', 'stamp_time'])
// 获取企业列表
const getEnterpriseList = async () => {
  formData.enterprise_id.loading = true
  try {
    const res = await enterpriseManagement.EnterpriseList({
      is_paginate: false,
      filter_status: 1
    })
    formData.enterprise_id.options = res.items
    formData.enterprise_id.loading = false
  } catch (error) {
    formData.enterprise_id.loading = false
  }
}
// 获取企业用户列表
const getEnterpriseUserList = async () => {
  await nextTick()
  formData.user_id.loading = true
  formData.user_id.value = null
  try {
    const res = await userManagement.roleUserList({
      filter_enterprise_id: formData.enterprise_id.value,
      filter_role_alias: 'Enterprise',
			is_paginate: false
    })
    formData.user_id.options = res.items
    formData.user_id.loading = false
  } catch (error) {
    formData.user_id.loading = false
  }
}
// 获取企业下产品列表
const getEnterpriseProduct = async () => {
  await nextTick()
  formData.product_id.loading = true
  formData.product_id.options = []
  try {
    const res = await productManagement.EnterpriseProduct({
      filter_enterprise_id: formData.enterprise_id.value,
      is_paginate: false
    })
    res.items.forEach(item => {
      formData.product_id.options.push({
        name: item.product.name,
        id: item.product_id
      })
    })
    formData.product_id.loading = false
  } catch (error) {
    formData.product_id.loading = false
  }
}
// 选择原订单
const selectOriginalOrder = async () => {
  await nextTick()
  if (!formData.enterprise_id.value) return
  orderlist.value.openWin(formData.enterprise_id.value)
}
// 选中订单
const setOrderInfo = (data) => {
  orderinfo.value = data
  orderInfoLoading.value = true
  formData.product_id.value = data.product_id // 补发订单产品id从原订单里取
  formData.parent_order_id.value = data.id
  orderInfo.value.forEach(item => {
    if (item.prop === 'can_use') { // 当前可用
      item.value = `${data.is_available ?  (data.available ? proxy.$numberToCurrencyNo(data.available.hosts.value) : '-') : '0'} ${t('unit.ge')}`
    } else if (item.prop === 'order_total') { // 订单总数
      item.value = `${data.purchased ? proxy.$numberToCurrencyNo(data.purchased.hosts.value) : '0'} ${t('unit.ge')}`
    } else if (item.prop === 'product_name') { // 产品名称
      item.value = data.product ? `${data.product.deployment.name}/${data.product.category.name}/${data.product.name}` : '-'
    } else {
      item.value = data[item.prop] || '-'
    }
  })
  orderInfoLoading.value = false
}
// 表单change事件
const change = async ({ item, prop, event }) => {
  if (prop === 'enterprise_id') {
    getEnterpriseUserList() // 获取企业用户列表
    if (formData.category_id.value !== 1002) getEnterpriseProduct() // 获取企业产品列表
    orderInfoLoading.value = true
    formData.parent_order_id.value = null
    formData.product_id.value = null
    const enterpriseInfo = item.options.find(ele => ele.id === item.value)
    formData.enterprise_name.value = enterpriseInfo ? enterpriseInfo.name : ''
  } else if (prop === 'category_id') {
    formData.product_id.hidden = item.value === 1002 // 补发时候隐藏选择产品select
    formData.parent_order_id.hidden = item.value !== 1002 // 补发时候显示选择原订单button
    formData.parent_order_id.options = []
    formData.parent_order_id.value = null
    formData.product_id.options = []
    formData.product_id.value = null
    if (!proxy.$userRole(['Enterprise'])) {
      formData.user_id.options = []
      formData.user_id.value = null
      formData.enterprise_id.value = null
    } else {
      getEnterpriseProduct()
    }
    orderInfoLoading.value = true
    // 新购订单需要填写相关商务字段
    fields.value.forEach(ele => {
      formData[ele].value = null
      formData[ele].hidden = ['delivery_method', 'is_stamp'].includes(ele) ? item.value !== 1001 : true
    })
  } else if (prop === 'delivery_method') {
    formData.expected_payment_time.hidden = item.value !== 'send_upon_payment'
    formData.expected_payment_time.value = null
    formData.stamp_time.value = null
    formData.stamp_method.value = null
    formData.electron_stamp_type.value = null
    formData.entity_stamp_type.value = null
  } else if (prop === 'is_stamp') {
    formData.stamp_method.hidden = item.value === '0'
    formData.stamp_time.hidden =  item.value === '0'
    formData.stamp_time.value = null
    formData.stamp_method.value = null
    formData.electron_stamp_type.value = null
    formData.entity_stamp_type.value = null
  } else if (prop === 'stamp_method') {
    formData.electron_stamp_type.hidden = item.value === 'entity' // 实体章
    formData.entity_stamp_type.hidden = item.value === 'digital' // 实体章
    formData.electron_stamp_type.value = null
    formData.entity_stamp_type.value = null
  }
}
// 提交表单
const onSubmit = async () => {
  const res = await form.value.submitForm()
  if (!!!res) return
  const postData = {
    purchased: {
      hosts: formData.quantity.value
    },
    params: {
      originator: store.state.users.role_id === 3 ? t('role.role3') : t('role.role4'), // 来源
      service_type: formData.service_type.value // 服务类型
    }
  }
  for (const key in formData) {
    if (fields.value.includes(key) && formData[key].value !== null) {
      postData.params[key] = formData[key].value
    } else if (!['quantity'].includes(key) && formData[key].value) {
      postData[key] = formData[key].value
    }
  }
  submitLoading.value = true
  try {
    const res = await AuthManagement.OrderCreate(postData)
    submitLoading.value = false
    // 新购不需要弹出，测试和补发订单需要弹出确认框
    if ((proxy.$userRole(['SalesMan']) || store.state.salesLoginId) && formData.category_id.value !== 1001) {
      closeWin()
      emit('getList')
      const action = await ElMessageBox.confirm(
        t('order.jump_and_confirm'),
        t('table.add_success'),
        {
          confirmButtonText: t('form.submit'),
          cancelButtonText: t('form.cancel'),
          type: 'success',
        }
      ).catch(() => {
        // 处理取消或其他错误
        submitLoading.value = false
      })
      if (action === 'confirm') {
        if (store.state.salesLoginId) {
          const result = await store.dispatch('backToSales', {
            id: store.state.salesLoginId,
            noBack: 'no',
          })
          if (result) {
            router.push('/authorization/order')
            emit('sureOrder', res)
          }
        } else {
          emit('sureOrder', res)
        }
      }
      return // 确保在确认框处理后返回
    }
    ElMessage.success(t('table.add_success'))
    closeWin()
    emit('getList')
  } catch (error) {
    submitLoading.value = false;
    // 处理错误，例如记录日志或显示错误消息
    console.error(error)
  }

}
const openWin = async () => {
  winShow.value = true
  await nextTick()
  formData.parent_order_id.hidden = true
  formData.product_id.hidden = false
  orderinfo.value = null
  orderInfoLoading.value = true
  // 非企业用户创建时候，显示选择企业下拉框
  formData.enterprise_id.hidden = !!proxy.$userRole(['Enterprise'])
  formData.user_id.hidden = !!proxy.$userRole(['Enterprise'])
  if (proxy.$userRole(['Enterprise'])) {
    // 企业登录创建时，默认选择登录企业用户的所属企业
    formData.enterprise_id.value = store.state.users.enterprise_id
    formData.enterprise_name.value = store.state.users.enterprise.name
    formData.user_id.value = store.state.users.id
    getEnterpriseProduct()
    return
  }
  getEnterpriseList()
}
// 关闭窗口
const closeWin = () => {
  if (form.value) form.value.clearForm()
  fields.value.forEach(ele => {
    formData[ele].value = null
    formData[ele].hidden = !['delivery_method', 'is_stamp'].includes(ele)
  })
  winShow.value = false
}

watch(() => formData, async () => {
  await nextTick()
  if (!orderinfo.value) return
  // 补发订单时候并且产品类型也是补发的时候显示服务类型
  formData.service_type.hidden = !(formData.category_id.value === 1002 && orderInfoLoading.value && orderinfo.value && orderinfo.value.product.category.id === 1002)
  // 订单数量最大值为原订单总数量
  formData.quantity.max = formData.category_id.value === 1002 && orderinfo.value.purchased.hosts.value || Infinity
}, { deep: true, immediate: true })

defineExpose({
  openWin
})
</script>

<style type="text/css" scoped>
.el-form-item {
  margin-bottom: 18px !important;
}
</style>
