
export default {
  system: {
    name: "授权管理平台",
    no_permission:'暂无系统操作权限，请联系管理员',
    welcome: '欢迎您',
    message: '站内消息通知',
    unread: '条未读',
    no_message: '暂无消息通知',
    role: '账号身份',
    changePwd: '修改密码',
    logout: '退出登录',
    account: '账号管理',
    userinfo: '进入管理页面',
    day: '天',
    days: '天',
    month: '月',
    year: '年',
    title_content: '标题内容',
    status: '状态',
    read: '已读',
    unread: '未读',
    type: '类型',
    system_notice: '站内通知',
    del_all: '确定删除全部通知吗？',
    del_select: '确定删除选中通知吗？',
    del_notice: '删除通知',
    del_all_button: '全部删除',
    read_button: '标记已读',
    id: '编号',
    phone_number: '手机号',
    action: '动作',
    user: '用户',
    instructions: '说明',
    language: '语言',
    zh: '中文',
    en: '英文',
    old_pwd: '原密码',
    new_pwd: '新密码',
    new_pwd_again: '再次输入新密码',
    not_right: '两次密码输入不一致',
    network_error: '请求超时，请检查网络连接',
    network_500: '内置服务器错误',
    tip: '温馨提示',
    error_404: '请求资源找不到',
    time_zone_project: '项目所在时区',
    time_zone: '时区'
  },
  role: {
    role1: '超级管理员',
    role2: '运营用户',
    role3: '销售用户',
    role4: '企业用户',
    role5: '企业子用户',
    unkown: '未知用户'
  },
  lang: {
    zh: '简体中文',
    en: 'English'
  },
  routes: {
    dashboard: '控制台',
    user: '用户管理',
    enterprise: '企业管理',
    enterprise_user: '企业用户',
    product: '产品管理',
    authorization: '授权管理',
    enterprise_product: '企业产品',
    project: '项目管理',
    behalf_project: '项目管理',
    order: '订单管理',
    behalf_order: '订单管理',
    general: '运维管理',
    salesman: '销售管理',
    subuser: '企业子用户',
    third_oauth: '三方认证',
    sales_product: '销售产品',
    test_project: '试用项目',
    assignment: '授权分配',
    behalf_auth: '授权分配',
    application: '申请管理',
    audit: '审计管理',
    backToSales: '返回到销售用户界面',
    message: '站内通知',
    userinfo: '账户信息'
  },
  // 登陆表单
  form: {
    inputPlaceholder: "请输入信息",
    selectPlaceholder: "请选择",
    username: '请输入用户名',
    usernameCheck: '用户名必须为 3 ~ 30 个字符',
    password: "请输入用密码",
    loginBtn: "登录",
    wrongUsername: "长度在3~5之间",
    thirdPartyLogin: '第三方登录',
    success: '登录成功',
    handleSuccess: '操作成功',
    submit: '确定',
    cancel: '取消',
    search_placeholder: '请输入内容',
    last_week: '最近一周' ,
    last_month: '最近一月' ,
    last_three_month: '最近三月',
    emptyErrMsg: '当前项不能为空。',
    pwdMessage: '两次输入密码不一致!',
    error_mail: '邮箱格式不正确',
    must_be_number: '输入必须为数字',
    must_be_number_letter: '只能输入字母或数字',
    create_success: '创建成功',
    update_success: '更新成功',
    next: '下一步',
    prev: '上一步',
    save: '保存',
    delete: '删除',
    close: '关闭',
    sure_del: '确定删除该条数据吗?',
    confirm: '确认',
    check: '审核',
    revoke: '撤销',
    agree: '同意',
    reject: '拒绝',
    pass: '通过',
    tip: '提示',
    add: '添加',
    select: '选择',
    username: '用户名'
  },
  table: {
    creation_time: '创建时间',
    registration_time: '注册时间',
    submission_time: '提交时间',
    expiration_time: '到期时间',
    last_login_time: '最后登录',
    login_time: '登录时间',
    start_time: '开始时间',
    end_time: '结束时间',
    apply_time: '申请时间',
    action: '操作',
    detail: '详情',
    modify: '编辑',
    create: '创建',
    custody: '代管',
    show_more: '查看更多',
    yes: '是',
    no: '否',
    export: '导出',
    export_success: '导出成功',
    export_failed: '导出失败',
    add_success: '添加成功',
    modify_success: '编辑成功',
    del_success: '删除成功',
    del_failed: '删除失败',
    bind_success: '绑定成功',
    select_del: '请选择要删除的数据',
    all: '全部',
    transfer_successful: '转入成功',
    transfer_out_successful: '转出成功',
    transfer_out: '转出',
    enable: '启用'
  },
  dashboard: {
    expired_license: '最近到期License',
    enterprise: '所属企业',
    project: '项目',
    sales: '销售人员',
    quantity: '数量',
    order_id: '订单编号'
  },
  user: {
    sure_jump_before: '确定要跳转到企业用户',
    sure_jump_after: '代管页面？',
    jump_tip: '跳转提示',
    username: '用户登录名',
    liaison_name: '联系人姓名',
    liaison: '联系人',
    liaison_mail: '联系人邮箱',
    sales: '所属销售',
    enterprise_user: '企业用户',
    enterprise_username: '企业用户登录名',
    password: '登录密码',
    enterprise_name: '所属企业名称',
    confirm_password: '确认密码',
    nick_name: '用户昵称',
    liaison_phone: '联系人手机号',
    enterprise_nick_name: '企业用户昵称',
    enterprise_Liaison: '企业联系人姓名',
    enterprise_liaison_phone: '企业联系人手机号',
    enterprise_liaison_mail: '企业联系人邮箱',
    custody: '销售代管',
    custody_tip: '开启后企业用户不可访问授权管理平台，由所属销售代管该企业用户在授权管理平台所有操作。',
    notes: '备注',
    enterprise_user_detail: '企业用户详情',
    sales_name: '销售名称',
    entry_time: '转入时间',
    exit_time: '转出时间',
    edit_userinfo: '编辑用户信息',
    allow_phone: '是否允许手机接收平台短信通知',
    allow: '允许',
    not_allow: '不允许',
    dingtalk_phone: '钉钉手机号',
    exist_sale_product: '该销售下已存在此产品',
    create_sale: '创建销售',
    modify_sale: '编辑销售',
    create_sale_user: '创建销售用户',
    third_party: '三方平台',
    union_id: '联合编号',
    third_id: '三方编号',
    common_sub_user: '共有子用户',
    create_sub_user: '创建子用户',
    edit_sub_user: '编辑子用户',
    sub_user_detail: '子用户详情',
    sub_user_mail: '子用户邮箱',
    sub_user: '子用户',
    sub_user_name: '姓名',
    sub_user_phone: '联系电话',
    is_first: '是否首次申请',
    name: '名称'
  },
  enterprise: {
    name: '企业名称',
    creator: '创建者名称',
    full_name: '请填写企业全称',
    code: '企业编码',
    review_status: '审核状态',
    related_products: '关联产品',
    create: '创建企业',
    detail: '企业详情',
    modify: '编辑企业',
    authorize_product: '授权产品',
    add_user: '添加企业用户',
    social_code: '统一社会代码',
    social_code_tip: '营业执照上显示',
    code_tip: '企业编码需要运营确认并填写',
    type: '企业类型',
    type_tip: '该企业类型已被创建,请重新选择',
    general: '通用类型',
    testing: '内部测试',
    scan: '扫码试用',
    code_type: '企业编码必须为字母',
    status: '企业状态',
    no_data: '未获取到企业信息，新增失败',
    exist_product: '该企业下已存在此产品',
    select_enterprise: '请选择企业',
    select_product: '请选择产品',
    transfer_enterprise: '转移企业',
    bind_dingtalk: '绑定钉钉',
    entry_enterprise: '转入企业',
    exit_enterprise: '转出企业',
    transfer: '企业转移',
    entry_enterprise_user: '转入企业用户',
    exit_enterprise_user: '转出企业用户',
    info: '企业信息',
    code_length: '企业编码长度必须为4~10位',
    code_type: '企业编码必须为字母',
    creator: '创建人姓名',
    creator_username: '创建人登录名',
    creator_phone: '创建人手机号',
    reject_create_apply: '确定要拒绝该企业创建申请吗？',
    pass_create_apply: '确定要通过该企业创建申请吗？',
    status_0: '待审核',
    status_9: '已拒绝',
    status_1: '审核通过',
    create_success: '已提交企业审核，请联系运营管理员及时审核'
  },
  product: {
    product_category: '产品大类',
    product_type: '产品类型',
    product_name: '产品名称',
    product_code: '产品编码',
    show_product: '查看产品',
    add_product: '添加产品',
    product_abbreviation: '产品简称',
    product_full_name: '产品全称',
    type_abbreviation: '类型缩写',
    category_abbreviation: '大类缩写',
    version: '版本号',
    edit: '编辑产品',
    create: '创建产品',
    saas: 'SAAS版',
    stda: '单机版',
    coem: 'OEM版',
    product_model: '产品型号',
    general: '通用产品',
    in_house: '内部研发产品',
    scan_to_try: '扫码试用产品',
    select_product_model: '请选择产品型号',
    select_product_category: '请选择产品大类',
    has_model: '该产品型号已被创建',
    custom_setting: '自定义设置',
    auth_address: '产品鉴权地址',
    admin_username: '管理员用户名',
    admin_password: '管理员密码',
    allow_auto: '是否允许自动下发授权',
    select_sale: '请选择销售',
    select_product: '请选择产品',
    create_select_pro: '选择产品',
    user_product: '产品用户名'
  },
  order: {
    type_1001: '新购',
    type_1002: '补发',
    type_1003: '测试',
    status_0: '待确认',
    status_1: '待审核',
    status_2: '审核通过',
    status_8: '已撤销',
    status_9: '已拒绝',
    order_total: '订单总数',
    license_total: 'license可用总数',
    order_create: '订单创建时间',
    order_user: '订单发起',
    order_code: '订单编号',
    order_type: '订单类型',
    fliter_mail: '分配邮箱',
    can_use: '当前可用',
    ass_end_time: '分配到期时间',
    last_ass_end_time: '上次分配到期时间',
    order_cycle: '使用周期',
    approval_status: '审批状态',
    order_status: '订单状态',
    available: '可用',
    unavailable: '不可用',
    failback: '回切',
    order: '订单',
    bind_project: '绑定项目',
    comment: '批注',
    add_comment: '添加批注',
    add_comment_success: '批注成功',
    revoke_order: '撤销订单',
    revoke_success: '撤销成功',
    show_project: '查看项目',
    related_projects: '关联项目',
    add_project: '添加项目',
    order_detail: '订单详情',
    allocation_history: '订单分配历史',
    asse_cycle: '分配周期',
    asse_cycle_tip: '分配周期是指订单生效后可以创建授权分配的时间段，超过分配周期订单将失效。',
    use_cycle: '使用周期是指软件授权的有效时长，使用周期的开始时间，以软件授权在本平台分配成功后，添加至万博智云迁移或容灾平台当天为计。',
    allocation_count: '分配数量',
    allocation_time: '分配时间',
    expiration_time: '使用到期时间',
    approval_at: '审核通过时间',
    original_order_code: '原订单编号',
    approve_order: '确定通过该订单吗?',
    action_order: '确定操作该订单吗?',
    confirm_order: '确定确认该订单吗?',
    create_order: '创建订单',
    select_enterprise_create: '选择企业',
    select_enterprise_user_create: '选择企业用户',
    select_product_create: '选择产品',
    select_origional_order: '选择原订单',
    select_service_type: '选择服务类型',
    buy_num: '订购数量',
    standard: '标准',
    jump_and_confirm: '是否跳转到销售进行订单确认？',
    delivery_method: '发货方式',
    pay_and_post: '付款后发货',
    post_and_pay: '发货后付款',
    expected_payment_time: '预计付款时间',
    is_stamp: '是否盖章',
    stamp_method: '盖章方式',
    real_stamp: '实体章',
    ele_stamp: '电子章',
    stamp_time: '用章时间',
    entity_stamp_type: '[实体章]用章类型',
    official_seal: '公章',
    contract_seal: '合同章',
    legal_representative_seal: '法人章',
    financial_seal: '财务章',
    electron_stamp_type: '[电子章]用章类型',
    last_assign_order_num: '上次分配订单数量'
  },
  project: {
    project_code: '项目编号',
    project_name: '项目名称',
    project_mail: '项目邮箱',
    transfer_status: '移交状态',
    transfer: '移交',
    original_enterprise: '原属企业',
    transferred_enterprise: '移交企业',
    transferred_enterprise_user: '移交企业用户',
    transfer_successful: '转交成功',
    sub_user: '所属子用户',
    status: '项目状态',
    license: '注册码',
    failback_key: '回切注册码',
    mail: '邮箱',
    edit_project: '编辑项目',
    create_project: '创建项目',
    enabled: '开启',
    assign_sub_user: '是否分配子用户',
    select_sub_user: '选择子用户',
    huawe_cloud_lic: '华为云授权码',
    detail: '项目详情',
    customer_po: '企业PO号'
  },
  assign: {
    status_0: '待分配',
    status_1: '已分配',
    status_8: '已撤销',
    status_9: '已拒绝',
    application_info: '申请信息',
    handed: '已交接',
    unhanded: '未交接',
    create_assign: '创建 & 分配',
    check_assign: '审核 & 分配',
    license_statistics: '授权统计',
    license_code: '授权编码',
    project: '所属项目',
    assigned_count: '分配数量',
    license_start_at: '授权生效时间',
    reissue: '再次下发',
    create_assignment: '创建分配',
    allocate_by_project: '按项目分配',
    allocate_by_license: '按注册码分配',
    license_key: '注册码',
    select_project: '选择项目',
    no_project: '没有找到项目',
    click_to_create: '点击此处创建',
    select_order: '选择订单',
    recipient_mail: '授权接收邮箱',
    authorization_method: '授权方式',
    author_license: '注册码授权',
    author_user: '产品用户授权',
    select_pro_user: '选择产品用户',
    add_mail: '新增接收邮箱',
    effective_immediately: '即时生效',
    scheduled: '定时生效',
    select_effective_time: '选择生效时间',
    send_mail_type: '发送邮件类型',
    no_project_lic: '未查询到该license对应项目，请重新输入',
    send_migraiton_mail: '发送迁移邮件',
    send_dr_failback: '容灾邮件以及回切邮件',
    send_dr_failback_mail: '发送容灾邮件以及回切邮件',
    send_dr_mail: '发送容灾邮件',
    dr: '容灾',
    migration: '迁移',
    send_failbackr_mail: '发送回切邮件',
    failback: '回切',
    detail: '分配详情',
    license_secret: '激活码',
    failback_secret: '回切激活码',
    generate_failback_secret: '生成回切激活码',
    send_email: '发送邮箱',
    product_users: '产品用户',
    receive: '接收',
    mail: '邮箱',
    mail_success: '邮件发送成功',
    mail_failed: '邮件发送失败',
    mail_sending: '邮件发送中',
    resend: '重发邮箱',
    custom_mail: '自定义邮箱',
    send_custom_mail: '发送自定义邮箱',
    issuance_status: '授权下发状态',
    sub_issuance_status: '下发状态',
    issuing: '下发中',
    issued_successfully: '下发成功',
    issued_failed: '下发失败',
    project_assign_history: '项目分配历史',
    activated_count: '已激活数量',
    inactive_count: '未激活数量',
    dr: '容灾',
    failback: '回切',
    authorized_count: '已授权数量',
    unauthorized_count: '未授权数量',
    reissue_tip: '确定要重新下发吗?',
    reissue_title: '重新下发',
    add_email: '添加邮箱',
    confirm_to_reissue: '确认重发',
    three_mail: '最多可添加三个接收邮箱',
    license_assign_failed: '创建分配授权时，License下发失败',
    assignment: '授权信息',
    selected_order: '已选订单',
    no_order_find: '没有找到订单',
    no_order_find_tip: '订单需要通过审批后方可选择',
    scan_code: '二维码扫码',
    sub_user_apply: '子用户申请',
    admin_create: '主用户创建',
    third_platform: '第三方平台',
    International: '国际测试申请',
    apply: '申请',
    success: '成功',
    application_creation_time: '申请创建时间',
    application_id: '申请编号',
    application_source: '申请来源',
    application_count: '申请数量',
    assign: '分配',
    product_user_mail: '产品用户邮箱',
    create_application: '创建申请',
    my_application: '我的申请',
    sub_user_select_order: '选择下发订单',
    application_detail: '申请详情',
    project_apply_history: '项目申请历史',
    historical_order_allocation: '分配历史订单',
    apply_status: '申请状态',
    assign_count: '授权数量',
    user_apply_history: '用户申请历史',
    add_receving_mail: '请添加接收邮箱',
    issuse_mail: '分配下发邮箱',
    type: '分配方式',
    no_project_product: '该项目下未绑定订单，请重新选择项目。',
    ressiue_order: '请选择下发订单',
    select_license_start_at: '请选择授权生效时间',
    last_assign_mail: '上次发送邮件类型'
  },
  unit: {
    ge: '个'
  }
}