<template>
  <div class="table_wrap">
    <div class="table">
      <el-table
        ref="table"
        v-loading="tableLoading"
        :cell-style="cellStyle"
        :header-cell-style="headerCellStyle"
        :data="tableInfo.data"
        :height="height"
        @selection-change="handleSelectionChange"
        style="height: 100%;"
        class="tables"
        stripe
        border>
        <el-table-column
          v-if="tableInfo.selection"
          align="center"
          :resizable="false"
          :selectable="tableInfo.checkBoxInt ? tableInfo.checkBoxInt : checkBoxInt"
          type="selection"
          width="55" />
        <template v-for="(item, index) in tableInfo.header">
          <el-table-column
            v-if="!!!item.hidden"
            :resizable="false"
            :sortable="item.sortable"
            :sort-by="item.sortBy ? item.sortBy : ''"
            :key="index"
            :label="item.label"
            :width="item.width"
            :min-width="item.minWidth"
            :prop="item.key"
            :fixed="item.fixed">
            <template #default="scope">
              <!-- 操作栏 -->
              <div v-if="item.handleFun" :style="{ width: item.width ? item.width : 'auto', minWidth: item.minWidth }">
                <template v-for="(handle, key) in item.handleFun" :key="key">
                  <span v-if="handle.hidden ? (!!!handle.hidden(scope.row)) : true">
                    <!-- 需确认 -->
                    <el-popconfirm v-if="handle.type"
                      :cancelButtonText="handle.cancelButtonText ? handle.cancelButtonText : t('form.cancel')"
                      @cancel="handle.cancel ? handle.cancel(scope.row) : ''" :title="handle.content"
                      @confirm="handle.fn(scope.row)">
                      <template #reference>
                        <span class="handle_custem" :class="{ disabled: handle.disabled }">{{ handle.name || t('form.delete')
                        }}</span>
                      </template>
                    </el-popconfirm>
                    <!-- 非确认 -->
                    <span class="handle_custem" v-else
                      :class="{ disabled: handle.disabled ? handle.disabled(scope.row) : false }"
                      @click="handle.fn ? handle.fn(scope.row) : ''">{{ handle.name }}</span>
                  </span>
                </template>
              </div>
              <div v-else class="tabel_item">
                <!-- 自定义 -->
                <el-tooltip :show-after="700" placement="top" effect="light">
                  <template #content>
                    <div v-if="item.hoverText">
                      <div class="line-2" v-if="item.custem" v-html="item.hoverText(scope.row)"></div>
                      <!-- 默认 -->
                      <div class="line-2" v-else>
                        {{ scope.row[item.hoverText] || '-' }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="line-2" v-if="item.custem" v-html="item.custem(scope.row)"></div>
                      <!-- 默认 -->
                      <div class="line-2" v-else>
                        {{ scope.row[item.key] || '-' }}
                      </div>
                    </div>
                  </template>
                  <div class="line-2" v-if="item.custem" @click="item.fn ? item.fn(scope.row) : ''"
                    v-html="item.custem(scope.row)"></div>
                  <el-input-number style="width: 80px !important" v-else-if="item.type === 'number'"
                    v-model="scope.row.number" :min="1" :precision="0" :max="item.max ? item.max(scope.row) : ''"
                    controls-position="right" />
                  <!-- 默认 -->
                  <div class="line-2" v-else @click="item.fn ? item.fn(scope.row) : ''">
                    {{ scope.row[item.key] || '-' }}
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page"
      v-if="tableInfo.totalPage">
      <el-pagination
        :total="parseInt(tableInfo.totalPage)"
        :page-size="tableInfo.pageSize"
        :current-page="currentPage"
        background
        @current-change="setCurrent"
        layout="total, prev, pager, next"
        size="small"
        class="mt-4" />
    </div>
  </div>
</template>

<script setup>
import { formateDate } from '@/utils/format'
import { watch, ref, inject } from 'vue'
const emit = defineEmits(['getList', 'onSelectData'])
const loading = ref(true)
const t = inject('t')
const currentPage = ref(1)
const props = defineProps({
  tableInfo: {
    type: Object,
    default: () => {
      return {
        selection: true,
        header: [],
        data: [],
        totalPage: 0,
        pageSize: 0
      }
    }
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: '100%'
  }
})

const cellStyle = () => {
  return {
    height: '56px',
    minWidth: '110px'
  }
}

// 表头样式
const headerCellStyle = () => {
  return {
    background: '#F2F2F2',
    padding: '0px !important',
    color: '#525252',
    fontSize: '14px !important',
    height: '31px !important',
    minWidth: '110px !important',
    lineHeight: '31px !important',
    boxSizing: 'border-box !important'
  }
}

// 默认全部可选
const checkBoxInt = () => {
  return true
}
// 多选事件
const handleSelectionChange = (data) => {
  emit('onSelectData', data)
}

// 分页数据改变触发
const setCurrent = (current) => {
  loading.value = true
  currentPage.value = current
  emit('getList', {
    page: current
  })
}
// 重置分页
const resetCurrent = (current = null) => {
  currentPage.value = current || 1
}

watch(() => props.tableInfo.data, val => {
  if (val) {
    loading.value = false
    val.forEach(item => {
      formateDate(item, 'table')
    })
  }
}, {
  deep: true,
  immediate: true
})
defineExpose({
  resetCurrent
})
</script>

<style lang="scss" scoped>
.table_wrap {
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  height: calc(100% - 32px);

  .table {
    height: calc(100% - 8vh);
    width: 100%;
    font-weight: 400 !important;
  }

  .page {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    .totals {
      font-size: $fontSize12;
      color: $color3;
      margin-right: 10px;
    }
  }
}

.el-button--small {
  padding: 0 2px !important;
}

:deep(.el-table .cell) {
  font-size: $fontSize14 !important;
  box-sizing: border-box;
  line-height: 21px !important;
  overflow: hidden !important;
}

.el-table__body-wrapper {
  overflow-y: scroll !important;
}

:deep(.el-table .el-table__cell) {
  box-sizing: border-box !important;
  padding: 7px 0 !important;
  line-height: 22px !important;
  font-size: $fontSize14 !important;
}

.handle_custem {
  color: $primary;
  padding: 0 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: $primaryLight !important;
  }

  &:active {
    color: $primaryDark !important;
  }
}

.disabled {
  color: $color7 !important;
  pointer-events: none;
  cursor: not-allowed;
}
:deep(.caret-wrapper) {
  width: 12px !important;
}

</style>