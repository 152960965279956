// 时区列表
export const timeZones = [
  {
    "id": "Etc/GMT+12",
    "name": "(GMT-12:00) GMT+12"
  },
  {
    "id": "Etc/GMT+11",
    "name": "(GMT-11:00) GMT+11"
  },
  {
    "id": "Pacific/Midway",
    "name": "(GMT-11:00) Midway Island, Samoa"
  },
  {
    "id": "Etc/GMT+10",
    "name": "(GMT-10:00) Hawaii"
  },
  {
    "id": "America/Adak",
    "name": "(GMT-10:00) Hawaii-Aleutian"
  },
  {
    "id": "Pacific/Honolulu",
    "name": "(GMT-10:00) Honolulu"
  },
  {
    "id": "Pacific/Marquesas",
    "name": "(GMT-09:30) Marquesas Islands"
  },
  {
    "id": "Etc/GMT+9",
    "name": "(GMT-09:00) GMT+9"
  },
  {
    "id": "America/Anchorage",
    "name": "(GMT-09:00) Alaska"
  },
  {
    "id": "Pacific/Gambier",
    "name": "(GMT-09:00) Gambier Islands"
  },
  {
    "id": "America/Los_Angeles",
    "name": "(GMT-08:00) Pacific Time (US & Canada)"
  },
  {
    "id": "Etc/GMT+8",
    "name": "(GMT-08:00) Pitcairn Islands"
  },
  {
    "id": "America/Ensenada",
    "name": "(GMT-08:00) Tijuana, Baja California"
  },
  {
    "id": "America/Dawson_Creek",
    "name": "(GMT-07:00) Arizona"
  },
  {
    "id": "America/Chihuahua",
    "name": "(GMT-07:00) Chihuahua, La Paz, Mazatlan"
  },
  {
    "id": "America/Mazatlan",
    "name": "(GMT-07:00) Mazatlan"
  },
  {
    "id": "America/Denver",
    "name": "(GMT-07:00) Mountain Time (US & Canada)"
  },
  {
    "id": "America/Phoenix",
    "name": "(GMT-07:00) Phoenix"
  },
  {
    "id": "America/Tijuana",
    "name": "(GMT-07:00) Tijuana"
  },
  {
    "id": "America/Whitehorse",
    "name": "(GMT-07:00) Whitehorse"
  },
  {
    "id": "America/Chicago",
    "name": "(GMT-06:00) Central Time (US & Canada)"
  },
  {
    "id": "Pacific/Easter",
    "name": "(GMT-06:00) Easter"
  },
  {
    "id": "Chile/EasterIsland",
    "name": "(GMT-06:00) Easter Island"
  },
  {
    "id": "America/Cancun",
    "name": "(GMT-06:00) Guadalajara, Mexico City, Monterrey"
  },
  {
    "id": "America/Guatemala",
    "name": "(GMT-06:00) Guatemala"
  },
  {
    "id": "America/Mexico_City",
    "name": "(GMT-06:00) Mexico City"
  },
  {
    "id": "America/Regina",
    "name": "(GMT-06:00) Regina"
  },
  {
    "id": "America/Belize",
    "name": "(GMT-06:00) Saskatchewan, Central America"
  },
  {
    "id": "America/Bogota",
    "name": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco"
  },
  {
    "id": "America/Havana",
    "name": "(GMT-05:00) Cuba"
  },
  {
    "id": "America/New_York",
    "name": "(GMT-05:00) Eastern Time (US & Canada)"
  },
  {
    "id": "America/Caracas",
    "name": "(GMT-04:30) Caracas"
  },
  {
    "id": "America/Asuncion",
    "name": "(GMT-04:00) Asuncion"
  },
  {
    "id": "America/Glace_Bay",
    "name": "(GMT-04:00) Atlantic Time (Canada)"
  },
  {
    "id": "America/Goose_Bay",
    "name": "(GMT-04:00) Atlantic Time (Goose Bay)"
  },
  {
    "id": "America/Campo_Grande",
    "name": "(GMT-04:00) Brazil"
  },
  {
    "id": "America/Cuiaba",
    "name": "(GMT-04:00) Cuiaba"
  },
  {
    "id": "Atlantic/Stanley",
    "name": "(GMT-04:00) Faukland Islands"
  },
  {
    "id": "America/Indianapolis",
    "name": "(GMT-04:00) Indianapolis"
  },
  {
    "id": "America/La_Paz",
    "name": "(GMT-04:00) La Paz"
  },
  {
    "id": "America/Santiago",
    "name": "(GMT-04:00) Santiago"
  },
  {
    "id": "America/Grand_Turk",
    "name": "(GMT-04:00) Grand Turk"
  },
  {
    "id": "America/Port-au-Prince",
    "name": "(GMT-04:00) Port-au-Prince"
  },
  {
    "id": "America/St_Johns",
    "name": "(GMT-03:30) Newfoundland"
  },
  {
    "id": "America/Argentina/Buenos_Aires",
    "name": "(GMT-03:00) Argentina"
  },
  {
    "id": "America/Bahia",
    "name": "(GMT-03:00) Bahia"
  },
  {
    "id": "America/Sao_Paulo",
    "name": "(GMT-03:00) Brasilia"
  },
  {
    "id": "America/Buenos_Aires",
    "name": "(GMT-03:00) Buenos Aires"
  },
  {
    "id": "America/Cayenne",
    "name": "(GMT-03:00) Cayenne"
  },
  {
    "id": "America/Godthab",
    "name": "(GMT-03:00) Greenland"
  },
  {
    "id": "America/Halifax",
    "name": "(GMT-03:00) Halifax"
  },
  {
    "id": "America/Montevideo",
    "name": "(GMT-03:00) Montevideo"
  },
  {
    "id": "America/Miquelon",
    "name": "(GMT-03:00) Miquelon, St. Pierre"
  },
  {
    "id": "America/Punta_Arenas",
    "name": "(GMT-03:00) Punta Arenas"
  },
  {
    "id": "America/Araguaina",
    "name": "(GMT-03:00) UTC-3"
  },
  {
    "id": "Etc/GMT+2",
    "name": "(GMT-02:00) GMT+2"
  },
  {
    "id": "America/Noronha",
    "name": "(GMT-02:00) Mid-Atlantic"
  },
  {
    "id": "Atlantic/Azores",
    "name": "(GMT-01:00) Azores"
  },
  {
    "id": "Atlantic/Cape_Verde",
    "name": "(GMT-01:00) Cape Verde Is."
  },
  {
    "id": "Europe/Belfast",
    "name": "(GMT+00:00) Greenwich Mean Time : Belfast"
  },
  {
    "id": "Europe/Dublin",
    "name": "(GMT+00:00) Greenwich Mean Time : Dublin"
  },
  {
    "id": "Europe/Lisbon",
    "name": "(GMT+00:00) Greenwich Mean Time : Lisbon"
  },
  {
    "id": "Europe/London",
    "name": "(GMT+00:00) Greenwich Mean Time : London"
  },
  {
    "id": "Africa/Abidjan",
    "name": "(GMT+00:00) Monrovia, Reykjavik"
  },
  {
    "id": "Atlantic/Reykjavik",
    "name": "(GMT+00:00) Reykjavik"
  },
  {
    "id": "Africa/Sao_Tome",
    "name": "(GMT+00:00) Sao Tome"
  },
  {
    "id": "UTC",
    "name": "(GMT+00:00) UTC"
  },
  {
    "id": "Europe/Amsterdam",
    "name": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
  },
  {
    "id": "Europe/Belgrade",
    "name": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
  },
  {
    "id": "Europe/Brussels",
    "name": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"
  },
  {
    "id": "Africa/Casablanca",
    "name": "(GMT+01:00) Casablanca"
  },
  {
    "id": "Africa/Lagos",
    "name": "(GMT+01:00) Lagos"
  },
  {
    "id": "Africa/Algiers",
    "name": "(GMT+01:00) West Central Africa"
  },
  {
    "id": "Africa/Windhoek",
    "name": "(GMT+01:00) Windhoek"
  },
  {
    "id": "Asia/Beirut",
    "name": "(GMT+02:00) Beirut"
  },
  {
    "id": "Europe/Berlin",
    "name": "(GMT+02:00) Berlin"
  },
  {
    "id": "Europe/Budapest",
    "name": "(GMT+02:00) Budapest"
  },
  {
    "id": "Africa/Cairo",
    "name": "(GMT+02:00) Cairo"
  },
  {
    "id": "Asia/Gaza",
    "name": "(GMT+02:00) Gaza"
  },
  {
    "id": "Africa/Blantyre",
    "name": "(GMT+02:00) Harare, Pretoria"
  },
  {
    "id": "Asia/Jerusalem",
    "name": "(GMT+02:00) Jerusalem"
  },
  {
    "id": "Africa/Johannesburg",
    "name": "(GMT+02:00) Johannesburg"
  },
  {
    "id": "Africa/Juba",
    "name": "(GMT+02:00) Juba"
  },
  {
    "id": "Europe/Kaliningrad",
    "name": "(GMT+02:00) Kaliningrad"
  },
  {
    "id": "Africa/Khartoum",
    "name": "(GMT+02:00) Khartoum"
  },
  {
    "id": "Europe/Minsk",
    "name": "(GMT+02:00) Minsk"
  },
  {
    "id": "Europe/Paris",
    "name": "(GMT+02:00) Paris"
  },
  {
    "id": "Asia/Damascus",
    "name": "(GMT+02:00) Syria"
  },
  {
    "id": "Africa/Tripoli",
    "name": "(GMT+02:00) Tripoli"
  },
  {
    "id": "Europe/Warsaw",
    "name": "(GMT+02:00) Warsaw"
  },
  {
    "id": "Africa/Addis_Ababa",
    "name": "(GMT+03:00) Addis Ababa"
  },
  {
    "id": "Asia/Amman",
    "name": "(GMT+03:00) Amman"
  },
  {
    "id": "Asia/Baghdad",
    "name": "(GMT+03:00) Baghdad"
  },
  {
    "id": "Europe/Bucharest",
    "name": "(GMT+03:00) Bucharest"
  },
  {
    "id": "Europe/Chisinau",
    "name": "(GMT+03:00) Chisinau"
  },
  {
    "id": "Asia/Hebron",
    "name": "(GMT+03:00) Hebron"
  },
  {
    "id": "Europe/Istanbul",
    "name": "(GMT+03:00) Istanbul"
  },
  {
    "id": "Europe/Kiev",
    "name": "(GMT+03:00) Kiev"
  },
  {
    "id": "Europe/Moscow",
    "name": "(GMT+03:00) Moscow, St. Petersburg, Volgograd"
  },
  {
    "id": "Africa/Nairobi",
    "name": "(GMT+03:00) Nairobi"
  },
  {
    "id": "Asia/Riyadh",
    "name": "(GMT+03:00) Riyadh"
  },
  {
    "id": "Europe/Volgograd",
    "name": "(GMT+03:00) Volgograd"
  },
  {
    "id": "Asia/Tehran",
    "name": "(GMT+03:30) Tehran"
  },
  {
    "id": "Asia/Dubai",
    "name": "(GMT+04:00) Abu Dhabi, Muscat"
  },
  {
    "id": "Europe/Astrakhan",
    "name": "(GMT+04:00) Astrakhan"
  },
  {
    "id": "Asia/Baku",
    "name": "(GMT+04:00) Baku"
  },
  {
    "id": "Indian/Mauritius",
    "name": "(GMT+04:00) Mauritius"
  },
  {
    "id": "Europe/Saratov",
    "name": "(GMT+04:00) Saratov"
  },
  {
    "id": "Europe/Samara",
    "name": "(GMT+04:00) Samara"
  },
  {
    "id": "Asia/Tbilisi",
    "name": "(GMT+04:00) Tbilisi"
  },
  {
    "id": "Asia/Yerevan",
    "name": "(GMT+04:00) Yerevan"
  },
  {
    "id": "Asia/Kabul",
    "name": "(GMT+04:30) Kabul"
  },
  {
    "id": "Asia/Yekaterinburg",
    "name": "(GMT+05:00) Ekaterinburg"
  },
  {
    "id": "Asia/Karachi",
    "name": "(GMT+05:00) Karachi"
  },
  {
    "id": "Asia/Qyzylorda",
    "name": "(GMT+05:00) Qyzylorda"
  },
  {
    "id": "Asia/Tashkent",
    "name": "(GMT+05:00) Tashkent"
  },
  {
    "id": "Asia/Calcutta",
    "name": "(GMT+05:30) Calcutta"
  },
  {
    "id": "Asia/Kolkata",
    "name": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
  },
  {
    "id": "Asia/Colombo",
    "name": "(GMT+05:30) Colombo"
  },
  {
    "id": "Asia/Katmandu",
    "name": "(GMT+05:45) Kathmandu"
  },
  {
    "id": "Asia/Dhaka",
    "name": "(GMT+06:00) Astana, Dhaka"
  },
  {
    "id": "Asia/Novosibirsk",
    "name": "(GMT+06:00) Novosibirsk"
  },
  {
    "id": "Asia/Omsk",
    "name": "(GMT+06:00) Omsk"
  },
  {
    "id": "Asia/Urumqi",
    "name": "(GMT+06:00) Urumqi"
  },
  {
    "id": "Asia/Rangoon",
    "name": "(GMT+06:30) Yangon (Rangoon)"
  },
  {
    "id": "Asia/Bangkok",
    "name": "(GMT+07:00) Bangkok, Hanoi, Jakarta"
  },
  {
    "id": "Asia/Barnaul",
    "name": "(GMT+07:00) Barnaul"
  },
  {
    "id": "Asia/Hovd",
    "name": "(GMT+07:00) Hovd"
  },
  {
    "id": "Asia/Krasnoyarsk",
    "name": "(GMT+07:00) Krasnoyarsk"
  },
  {
    "id": "Asia/Tomsk",
    "name": "(GMT+07:00) Tomsk"
  },
  {
    "id": "Asia/Shanghai",
    "name": "(GMT+08:00) Asia/Shanghai"
  },
  {
    "id": "Asia/Hong_Kong",
    "name": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
  },
  {
    "id": "Asia/Irkutsk",
    "name": "(GMT+08:00) Irkutsk, Ulaan Bataar"
  },
  {
    "id": "Australia/Perth",
    "name": "(GMT+08:00) Perth"
  },
  {
    "id": "Asia/Singapore",
    "name": "(GMT+08:00) Singapore"
  },
  {
    "id": "Asia/Taipei",
    "name": "(GMT+08:00) Taipei"
  },
  {
    "id": "Asia/Ulaanbaatar",
    "name": "(GMT+08:00) Ulaanbaatar"
  },
  {
    "id": "Australia/Eucla",
    "name": "(GMT+08:45) Eucla"
  },
  {
    "id": "Asia/Chita",
    "name": "(GMT+09:00) Chita"
  },
  {
    "id": "Asia/Tokyo",
    "name": "(GMT+09:00) Osaka, Sapporo, Tokyo"
  },
  {
    "id": "Asia/Pyongyang",
    "name": "(GMT+09:00) Pyongyang"
  },
  {
    "id": "Asia/Seoul",
    "name": "(GMT+09:00) Seoul"
  },
  {
    "id": "Asia/Yakutsk",
    "name": "(GMT+09:00) Yakutsk"
  },
  {
    "id": "Australia/Adelaide",
    "name": "(GMT+09:30) Adelaide"
  },
  {
    "id": "Australia/Darwin",
    "name": "(GMT+09:30) Darwin"
  },
  {
    "id": "Australia/Brisbane",
    "name": "(GMT+10:00) Brisbane"
  },
  {
    "id": "Australia/Hobart",
    "name": "(GMT+10:00) Hobart"
  },
  {
    "id": "Pacific/Port_Moresby",
    "name": "(GMT+10:00) Port Moresby"
  },
  {
    "id": "Australia/Sydney",
    "name": "(GMT+10:00) Sydney"
  },
  {
    "id": "Asia/Vladivostok",
    "name": "(GMT+10:00) Vladivostok"
  },
  {
    "id": "Australia/Lord_Howe",
    "name": "(GMT+10:30) Lord Howe Island"
  },
  {
    "id": "Pacific/Bougainville",
    "name": "(GMT+11:00) Bougainville"
  },
  {
    "id": "Pacific/Guadalcanal",
    "name": "(GMT+11:00) Guadalcanal"
  },
  {
    "id": "Asia/Magadan",
    "name": "(GMT+11:00) Magadan"
  },
  {
    "id": "Asia/Sakhalin",
    "name": "(GMT+11:00) Sakhalin"
  },
  {
    "id": "Etc/GMT-11",
    "name": "(GMT+11:00) Solomon Is., New Caledonia"
  },
  {
    "id": "Asia/Srednekolymsk",
    "name": "(GMT+11:00) Srednekolymsk"
  },
  {
    "id": "Pacific/Norfolk",
    "name": "(GMT+11:30) Norfolk Island"
  },
  {
    "id": "Asia/Anadyr",
    "name": "(GMT+12:00) Anadyr, Kamchatka"
  },
  {
    "id": "Pacific/Auckland",
    "name": "(GMT+12:00) Auckland, Wellington"
  },
  {
    "id": "Pacific/Fiji",
    "name": "(GMT+12:00) Fiji"
  },
  {
    "id": "Etc/GMT-12",
    "name": "(GMT+12:00) Fiji, Kamchatka, Marshall Is."
  },
  {
    "id": "Asia/Kamchatka",
    "name": "(GMT+12:00) Kamchatka"
  },
  {
    "id": "Pacific/Chatham",
    "name": "(GMT+12:45) Chatham Islands"
  },
  {
    "id": "Pacific/Apia",
    "name": "(GMT+13:00) Apia"
  },
  {
    "id": "Etc/GMT-13",
    "name": "(GMT+13:00) GMT-13"
  },
  {
    "id": "Pacific/Tongatapu",
    "name": "(GMT+13:00) Nuku'alofa"
  },
  {
    "id": "Pacific/Kiritimati",
    "name": "(GMT+14:00) Kiritimati"
  }
]