<template>
  <div class="layout">
    <div class="slider" :class="{
      collapse: isCollapse, backgroundColor: store
        .state.salesLoginId
    }">
      <Slider :isCollapse="isCollapse" :is_mobile="is_mobile" />
    </div>
    <!-- 右侧 -->
    <div class="content">
      <div class="header">
        <div class="select" v-if="!!!is_mobile">
          <!-- <div class="hidden_btn" @click="isCollapse = !isCollapse">
            <el-icon v-if="!isCollapse">
              <Fold />
            </el-icon>
            <el-icon v-else>
              <Expand />
            </el-icon>
          </div> -->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
              <span v-if="item.children && item.children.length > 1">{{ t(`routes.${item.name}`) }}</span>
              <template v-else>
                <a v-if="item.name" @click.prevent="handleLink(item)">{{ t(`routes.${item.name}`) }}</a>
              </template>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div v-else>{{ t('system.name') }}</div>
        <div class="userinfo">
          <div class="right_bar">
            <div :title="timeZone.id" class="time_zone">
              <el-tooltip
                :content="timeZone.id"
                placement="bottom"
                effect="light"
              >
              <span><svg-icon icon-class="timeZone" class="time-zone-icon"></svg-icon>{{ timeZone.display_name }}</span>
              </el-tooltip>
            </div>
            <div class="back_sales" v-show="store.state.salesLoginId" @click="backToSales">{{ t('routes.backToSales') }}></div>
            <div class="welcome"> {{ t('system.welcome') }},
              <span class="el-dropdown-link" v-if="!!!is_mobile">
                {{ store.state.users ? store.state.users.nick_name : store.state.users.nick_name ? store.state.users.name
                  : store.state.users.name ? store.state.users.phone : '-' }}
              </span>
            </div>
            <!-- 通知 -->
            <el-popover :width="400"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
              <template #reference>
                <div class="message">
                  <el-icon color="#7f7f7f">
                    <Bell />
                  </el-icon>
                  <span class="point" v-if="notifiList.length"></span>
                </div>
              </template>
              <template #default>
                <div class="mess-wrap">
                  <div class="mess-title">
                    <div>{{ t('system.message') }}</div>
                    <div>{{ notifiList.length || '0' }} {{ t('system.unread') }}</div>
                  </div>
                  <div class="mess-list">
                    <template v-if="notifiList.length">
                      <div class="mess-item" v-for="(item, index) in notifiList" :key="index" @click="goMessDetail(item)">
                        <div class="mess-info line-1">{{ item.notice_title || '' }}</div>
                        <div class="mess-time">{{ changeTime(item.created_at) || '-' }}</div>
                      </div>
                    </template>
                    <div class="nomess" v-else><span>{{ t('system.no_message') }}</span></div>
                  </div>
                  <div class="mess-bottom">
                    <span @click="router.push('/message')">{{ t('table.show_more') }}>></span>
                  </div>
                </div>
              </template>
            </el-popover>
            <!-- 竖线 -->
            <div class="bar_line"></div>
            <!-- 语言 -->
            <lang-select class="margin-r2"></lang-select>
            <!-- 头像 -->
            <div class="avator">
              <el-icon>
                <User />
              </el-icon>
            </div>
            <el-dropdown>
              <span class="arrow_down">
                <el-icon>
                  <ArrowDownBold />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu style="width: 200px">
                  <el-dropdown-item>{{ t('system.role') }}: {{ $userTextChange(
                    store.state.users ? store.state.users.role_id : ''
                  ) }}</el-dropdown-item>
                  <el-dropdown-item>
                    <span>{{ t('system.account') }}: </span>
                    <p @click="router.push('/userinfo')">{{ t('system.userinfo') }}</p>
                  </el-dropdown-item>
                  <el-dropdown-item @click="changePwd">{{ t('system.changePwd') }}</el-dropdown-item>
                  <el-dropdown-item @click="logOut">{{ t('system.logout') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <!-- 显示区域 -->
      <div class="view">
        <div class="show_box">
          <router-view />
        </div>
      </div>
    </div>
    <!-- 修改密码 -->
    <UpdatePwd ref="updatePwd" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, computed } from 'vue'
import UpdatePwd from './components/updatePwd'
import Slider from './components/Slider'
import store from '@/store'
import { Bell } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { Notification, notificationRead } from '@/utils/api'
import { getLocalTimeZone } from '@/utils/common'

const t = inject('t')
const route = useRoute()
const router = useRouter()
const updatePwd = ref()
const notifiList = ref([])
const isCollapse = ref(false)
const is_mobile = ref(false)

const breadList = computed(() => {
  let matched = JSON.parse(JSON.stringify(route.matched))
  matched = matched.filter(item => item.name)
  return matched.length ? matched : []
})
const timeZone = getLocalTimeZone()

// 获取系统未读消息列表
const notificationList = () => {
  Notification({
    filter_status: false,
    is_paginate: false
  }).then(res => {
    notifiList.value = res.items
  })
}

const changeMessStatus = (id) => {
  notificationRead({
    ids: [id]
  }).then(res => { })
}

const goMessDetail = (item) => {
  changeMessStatus(item.uuid)
  notificationList()
  switch (item.module) {
    case 'order':
      if (store.state.users.role.alias !== 'Enterprise')
        router.push('/authorization/order')
      else router.push('/behalf_order')
      break;
    case 'assignment':
      if (store.state.users.role.alias !== 'Enterprise')
        router.push('/authorization/assignment')
      else router.push({
        path: '/behalf_auth',
        query: {
          active: '1'
        }
      })
      break;
    case 'allocation':
      if (store.state.users.role.alias !== 'Enterprise')
        router.push('/authorization/application')
      else router.push({
        path: '/behalf_auth',
        query: {
          active: '2'
        }
      })
      break;

    default:
      break;
  }
}

// 返回到销售用户界面
const backToSales = async () => {
  const res = await store.dispatch('backToSales', {
    id: store.state.salesLoginId
  })
  if (res) {
    router.push('/user/enterprise_user')
  }
}

const handleLink = (item) => {
  router.push(item.path)
}

watch(() => route, val => {
  if (store.state.users) notificationList()
}, { immediate: true, deep: true })


const winResize = () => {
  let width = document.documentElement.getBoundingClientRect().width;
  isCollapse.value = width < 750;
  is_mobile.value = width < 750;
}

// 时间转化
const changeTime = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
}

onMounted(() => {
  //屏幕切换时的处理
  winResize()
  window.addEventListener("resize", winResize, false);
  notificationList();
})

// 退出登录
const logOut = () => {
  store.dispatch('LOG_OUT')
}

// 修改密码
const changePwd = () => {
  if (!!!updatePwd.value) return
  updatePwd.value.openWin()
}
</script>

<style lang="scss">
@import './index.scss';
</style>