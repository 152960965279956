<template>
	<div class="form" style="width: 100% !important;">
		<!-- 订单列表 -->
		<div class="ordertable">
			<el-table
        ref="table"
        v-loading="loading"
        :row-key="getRowKeys"
				:data="tableInfo.data"
				:header-cell-style="{
          background: '#F2F2F2',
          color: '#525252',
          fontSize: '13px !important',
          height: '30px !important',
          lineHeight: '30px !important'
        }"
				stripe
        height="400"
        border
        @selection-change="handleSelectionChange">
				<el-table-column reserve-selection type="selection" width="40" :selectable="checkBoxInt" />
				<el-table-column property="date" :label="t('order.allocation_count')" width="115" fixed="left">
					<template #default="scope">
            <el-input-number
              v-model="scope.row.number"
              :min="1"
              :max="scope.row.max || 1"
              @change="numberChange(scope.row)"
              controls-position="right"
              style="width: 90px !important;">
              <template #decrease-icon>
                <el-icon>
                  <Minus />
                </el-icon>
              </template>
              <template #increase-icon>
                <el-icon>
                  <Plus />
                </el-icon>
              </template>
            </el-input-number>
					</template>
				</el-table-column>
				<el-table-column property="category_id" :label="t('order.order_type')" width="114" sortable :sort-by="'category_id'">
					<template #default="scope">
						{{ $formatType(scope.row.category_id, scope.row.params.service_type === 'failback' ? t('order.failback') : '') }}
					</template>
				</el-table-column>
				<el-table-column property="purchased" :label="t('order.order_total')" width="114" sortable :sort-by="'purchased.hosts.value'">
					<template #default="scope">
						<span>{{ $numberToCurrencyNo(scope.row.purchased.hosts.value) }} {{ t('unit.ge') }}</span>
					</template>
				</el-table-column>
				<el-table-column property="available" :label="t('order.can_use')" sortable :sort-by="'available.hosts.value'" width="114">
					<template #default="scope">
						<span v-if="scope.row.is_available">
							{{ $numberToCurrencyNo(scope.row.available.hosts.value) }} {{ t('unit.ge') }}</span>
						<span v-else>0</span>
					</template>
				</el-table-column>
				<el-table-column property="use_period" :label="t('order.order_cycle')" width="114" sortable :sort-by="'use_period'">
					<template #default="scope">
						<span v-if="scope.row.use_period">{{ $numberToCurrencyNo(scope.row.use_period) }} {{ Number(scope.row.use_period) > 1 ? t('system.days') : t('system.day') }}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column property="expired_at" :label="t('order.ass_end_time')" width="220" sortable :sort-by="'expired_at'">
					<template #default="scope">
						{{ timeFormat(scope.row.expired_at) }}
					</template>
				</el-table-column>
				<el-table-column property="code" :label="t('order.order_code')" width="180" sortable :sort-by="'code'" />
				<el-table-column property="is_available" :label="t('order.order_status')" width="118" sortable fixed="right" :sort-by="'is_available'">
					<template #default="scope">
						<!-- 自定义 -->
						<el-tooltip :show-after="700" placement="top" effect="light">
							<template #content>
								<p class="finished approved" v-if="scope.row.is_available">{{ t('order.available') }}</p>
								<p class="faild approved line-2" v-else>{{ scope.row.unavailability_reason }}</p>
							</template>
							<p class="finished approved" v-if="scope.row.is_available">{{ t('order.available') }}</p>
							<p class="faild approved" v-else>{{ t('order.unavailable') }}</p>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="bottoms">
				<div class="noproject">{{ t('assign.no_order_find') }}，
					<span @click="createOrder" class="create_order">
						{{ t('assign.click_to_create') }}
					</span>，{{ t('assign.no_order_find_tip') }}
				</div>
				<Pager :totalPage="tableInfo.totalPage" :pageSize="tableInfo.pageSize" @getList="getOrderList" />
			</div>
			<!-- 新增订单表单 -->
			<create-order-form ref="createOrderForm" />
		</div>
	</div>
</template>

<script setup>
import dayjs from 'dayjs'
import CreateOrderForm from '@/views/Authorization/Order/components/Form.vue'
import {
	ref,
	reactive,
	getCurrentInstance, inject
} from 'vue'
import {
	AuthManagement
} from '@/utils/api'

const t = inject('t')
const table = ref()
const { proxy } = getCurrentInstance()
const emit = defineEmits(['pushOrder'])

const createOrderForm = ref()
const searchData = ref({})
const loading = ref(false)
const tableInfo = reactive({
	data: [],
	totalPage: 0,
	pageSize: 0
})

const getRowKeys = (data) => {
	return data.id
}

// 创建订单
const createOrder = () => {
	if (!!!createOrderForm.value) return
	createOrderForm.value.openWin()
}
// 设置默认选中
const toggleSelection = (rows = []) => {
  if (rows) {
    rows.forEach((row) => {
      table.value.toggleRowSelection(row, undefined)
    })
  } else {
    table.value.clearSelection()
  }
}
// 设置不可用订单表格不可选
const checkBoxInt = (row, index) => {
  return !!!row.is_available ? 0 : 1
}

const timeFormat = (time) => {
  return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '-'
}

let selectData = []
const handleSelectionChange = (data) => {
	selectData = []
	selectData.push(...data)
	emit('pushOrder', selectData)
}

const numberChange = (data) => {
	selectData.forEach(item => {
		if (item.id === data.id) {
			item.number = data.number
		}
	})
	emit('pushOrder', selectData)
}

// 获取订单列表
const getOrderList = async (search = {}, order_id, number = 1) => {
  loading.value = true
  try {
    searchData.value = {
      ...searchData.value,
      ...search
    }
    const data = await AuthManagement.OrderAssignmentList({
      ...searchData.value
    })
    tableInfo.data = []
		data.items.forEach(ele => {
			ele.number = 1
			ele.max = ele.available.hosts.value
			tableInfo.data.push(ele)
		})
		tableInfo.totalPage = data.total
		tableInfo.pageSize = data.per_page
    if (order_id) {
      const selectArr = [] 
      tableInfo.data.forEach(item => {
        if (item.id === order_id) {
          item.number = number
          selectArr.push(item)
        }
      })
      toggleSelection(selectArr)
	    emit('pushOrder', selectArr)
    } else {
      toggleSelection()
	    emit('pushOrder', [])
    }
    loading.value = false
  } catch (error) {
    loading.value = false
  }
}

// 清空订单列表
const clearOrderList = () => {
	tableInfo.data = [];
	tableInfo.totalPage = 0
	tableInfo.pageSize = 0
	selectData.value = []
	table.value.clearSelection()
}


defineExpose({
	getOrderList: getOrderList,
	clearOrderList: clearOrderList
})
</script>

<style lang="scss" scoped>
.select_bar {
	display: flex;
	align-items: center;
}

:deep(.el-table__body-wrapper) {
	max-height: 400px;
	overflow-y: scroll;
}

.form_bar {
	width: 150px !important;
}

.bottoms {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.page {
		flex: 1 !important;
		width: auto !important;
	}
}
.create_order {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>
